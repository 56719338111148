import { ROUTES_DATA } from '@/data'

export const getCleanPathname = (pathname, locale) => {
  let cleanPath = pathname.replace(`/${locale}`, '')
  cleanPath = cleanPath.length > 1 ? cleanPath.replace(/\/$/, '') : cleanPath
  cleanPath = cleanPath.replace(/\/+/g, '/')
  return cleanPath || '/'
}

export const findMatchingRoute = (pathname) => {
  for (const key in ROUTES_DATA) {
    const { path } = ROUTES_DATA[key]

    const routeRegex = new RegExp(
      '^' +
      path
        .replace(/\[.+?\]/g, '[^/]+')
        .replace(/\//g, '\\/') +
      '$'
    )

    if (routeRegex.test(pathname)) {
      return { path, key }
    }
  }

  return null
}