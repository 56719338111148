// Match Config
export const MAGAZINE_PREVIEW_LIMIT = 8
export const TIKTOK_PREVIEW_LIMIT = 11
export const RADIO_PREVIEW_LIMIT = 20
export const PLAYLIST_FEATURED_PREVIEW_LIMIT = 20
export const AUTO_ACCEPT_MIN_MATCH_RATE = 65
export const AUTO_ACCEPT_MAX_MATCH_RATE = 95
export const RECENT_MATCH_LIMIT = 10

export const MATCH_CONFIG = {
  ITEMS_RANGE: [8, 16],
  DELAY_RANGE: [200, 1800],
  SCORE_FETCH_THROTTLE: 500,
  PER_PAGE: 20,
  REFETCH_MAX_ITEMS: 500,
}

export const MATCH_TYPES = {
  SPOTIFY: 'spotify',
  TIKTOK: 'tiktok',
  RADIO: 'radio',
  MAGAZINE: 'magazine',
}

export const MATCH_MESSAGES = {
  en: {
    magazine: "<p>Hello, I found your magazine on Matchfy, and I would like to send you my press kit for consideration of an article.</p><p>You can listen to my song on Spotify at this link:</p><p>{trackLink}</p><p>Furthermore, I have included some photographs, biography, and a presentation of my track (Please provide a link to streamline the transfer of all the contents).</p><p>Looking forward to your response soon!</p>",
    spotify: "<p>Dear {curatorName},</p><p>My name is <b>{userName}</b>, I just matched your cool playlist {playlistName} on matchfy.io with my song, at <b>{score}% match rate</b>. Considering the above match rate, you might take my song into consideration for your playlist. You can listen to it on Spotify: <br /> {trackLink}</p><p>Let me know if you are interested on adding it to your playlist.</p><p>Thank you!<br />{userName}</p>",
    tiktok: "<p>Hello, I found your profile on Matchfy, and I would like to send you my music.</p><p>You can listen to it on Spotify at this link:</p><p>{trackLink}</p><p>I hope you like it, and consider using it in your future content! Thank you!</p>"
  },
  es: {
    magazine: "<p>Hola, encontré tu revista en Matchfy y me gustaría enviarte mi press kit para considerar un artículo.</p><p>Puedes escuchar mi canción en Spotify en este enlace:</p><p>{trackLink}</p><p>Además, he incluido algunas fotografías, biografía y una presentación de mi pista (Por favor proporciona un enlace para simplificar la transferencia de todos los contenidos).</p><p>¡Espero tu respuesta pronto!</p>",
    spotify: "<p>Estimado/a {curatorName},</p><p>Mi nombre es <b>{userName}</b>, acabo de hacer match con tu increíble playlist {playlistName} en matchfy.io con mi canción, con una <b>tasa de match del {score}%</b>. Considerando esta tasa de match, podrías tomar en cuenta mi canción para tu playlist. Puedes escucharla en Spotify: <br /> {trackLink}</p><p>Hazme saber si estás interesado/a en agregarla a tu playlist.</p><p>¡Gracias!<br />{userName}</p>",
    tiktok: "<p>Hola, encontré tu perfil en Matchfy y me gustaría enviarte mi música.</p><p>Puedes escucharla en Spotify en este enlace:</p><p>{trackLink}</p><p>¡Espero que te guste y consideres usarla en tu contenido futuro! ¡Gracias!</p>"
  },
  fr: {
    magazine: "<p>Bonjour, j'ai trouvé votre magazine sur Matchfy et je souhaiterais vous envoyer mon dossier de presse pour considération d'un article.</p><p>Vous pouvez écouter ma chanson sur Spotify à ce lien :</p><p>{trackLink}</p><p>De plus, j'ai inclus quelques photos, une biographie et une présentation de mon titre (Veuillez fournir un lien pour simplifier le transfert de tous les contenus).</p><p>Dans l'attente de votre réponse !</p>",
    spotify: "<p>Cher/Chère {curatorName},</p><p>Je m'appelle <b>{userName}</b>, je viens de matcher avec votre superbe playlist {playlistName} sur matchfy.io avec ma chanson, avec un <b>taux de match de {score}%</b>. Compte tenu de ce taux de match, vous pourriez prendre ma chanson en considération pour votre playlist. Vous pouvez l'écouter sur Spotify : <br /> {trackLink}</p><p>Faites-moi savoir si vous êtes intéressé(e) à l'ajouter à votre playlist.</p><p>Merci !<br />{userName}</p>",
    tiktok: "<p>Bonjour, j'ai trouvé votre profil sur Matchfy et je souhaiterais vous envoyer ma musique.</p><p>Vous pouvez l'écouter sur Spotify à ce lien :</p><p>{trackLink}</p><p>J'espère qu'elle vous plaira et que vous envisagerez de l'utiliser dans vos futurs contenus ! Merci !</p>"
  },
  it: {
    magazine: "<p>Ciao, ho trovato la tua rivista su Matchfy e vorrei inviarti il mio press kit per considerare un articolo.</p><p>Puoi ascoltare la mia canzone su Spotify a questo link:</p><p>{trackLink}</p><p>Inoltre, ho incluso alcune fotografie, biografia e una presentazione della mia traccia (Per favore fornisci un link per semplificare il trasferimento di tutti i contenuti).</p><p>In attesa di una tua risposta!</p>",
    spotify: "<p>Caro/a {curatorName},</p><p>Mi chiamo <b>{userName}</b>, ho appena fatto match con la tua fantastica playlist {playlistName} su matchfy.io con la mia canzone, con un <b>tasso di match del {score}%</b>. Considerando questo tasso di match, potresti prendere in considerazione la mia canzone per la tua playlist. Puoi ascoltarla su Spotify: <br /> {trackLink}</p><p>Fammi sapere se sei interessato/a ad aggiungerla alla tua playlist.</p><p>Grazie!<br />{userName}</p>",
    tiktok: "<p>Ciao, ho trovato il tuo profilo su Matchfy e vorrei inviarti la mia musica.</p><p>Puoi ascoltarla su Spotify a questo link:</p><p>{trackLink}</p><p>Spero che ti piaccia e che consideri di usarla nei tuoi contenuti futuri! Grazie!</p>"
  }
}
