import { isBrowser } from '@/utils'

export const loadScript = (src, id, async = true, head = true) => {
  if (!isBrowser) return
  const script = document.createElement('script')
  script.src = src
  script.id = id
  script.async = async

  if (head) {
    document.head.appendChild(script)
  } else {
    document.body.appendChild(script)
  }
}

export const loadGoogleMapScript = (
  googleMapsScriptBaseUrl,
  googleMapsScriptUrl
) => {
  if (!isBrowser) return Promise.resolve()

  if (typeof google !== 'undefined') {
    if (window.google.maps && window.google.maps.api) return Promise.resolve()
  }

  const scriptElements = document.querySelectorAll(
    `script[src*="${googleMapsScriptBaseUrl}"]`
  )

  if (scriptElements && scriptElements.length) {
    return new Promise((resolve) => {
      if (typeof google !== 'undefined') return resolve()
      scriptElements[0].addEventListener('load', () => resolve())
    })
  }

  let scriptUrl = new URL(googleMapsScriptUrl)
  scriptUrl.searchParams.set(
    'callback',
    '__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__'
  )
  const el = document.createElement('script')
  el.src = scriptUrl.toString()

  return new Promise((resolve) => {
    window.__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__ = resolve
    document.body.appendChild(el)
  })
}
