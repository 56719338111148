import { PLANS, ROUTES } from '@/data'

export const planRedirect = (plan) => {
  const planParam = 'plan'

  switch (plan) {
    case PLANS.ARTIST:
      return `${ROUTES.PRICING}?${planParam}=${PLANS.ARTIST}`
    case PLANS.AGENCY:
      return `${ROUTES.PRICING}?${planParam}=${PLANS.AGENCY}`
    default:
      return ROUTES.BILLING
  }
}
