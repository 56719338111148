export const CREDIT_DISCOUNT_PERCENTAGE = 0.8
export const CURRENCY = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const PAYMENT_REDIRECT_TIMEOUT = 3000
export const PROFILE_COMPLETE_BONUS = 5
export const USER_ROYALTIES_PERCENTAGE = 0.95
export const VAT_AMOUNT = 22
export const VIP_CAMPAIGN_COMMISSION_PERCENTAGE = 5

export const STRIPE_ARTIST_ID = process.env.NEXT_PUBLIC_STRIPE_ARTIST_ID
export const STRIPE_AGENCY_ID = process.env.NEXT_PUBLIC_STRIPE_AGENCY_ID
export const STRIPE_ANALYSIS_ID = process.env.NEXT_PUBLIC_STRIPE_ANALYSIS_ID
export const STRIPE_PROFILE_FEATURED_ID = process.env.NEXT_PUBLIC_STRIPE_PROFILE_FEATURED_ID
export const STRIPE_PLAYLIST_FEATURED_ID = process.env.NEXT_PUBLIC_STRIPE_PLAYLIST_FEATURED_ID
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY
export const STRIPE_SECRET_KEY = process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY

export const PLANS = {
  FREE: 'free',
  CURATOR: 'curator',
  ARTIST: 'artist',
  AGENCY: 'agency',
  VIP: 'vip',
}

export const USER_PLANS = {
  [PLANS.FREE]: 'free',
  [PLANS.ARTIST]: 'artist',
  [PLANS.AGENCY]: 'agency',
}

export const PLAN_CONFIG = {
  free: {
    title: 'Curator / Promoter',
    icon: 'ListMusic',
    color: 'tertiary',
    slug: 'free',
  },
  artist: {
    title: 'Artist',
    icon: 'Headphones',
    color: 'secondary',
    slug: 'artist',
  },
  agency: {
    title: 'Agency',
    icon: 'Disc3',
    color: 'primary',
    slug: 'agency',
  },
  vip: {
    title: 'VIP',
    icon: 'Gem',
    color: 'gold',
    slug: 'vip',
  },
}

export const PLAN_PRICES = {
  free: {
    price: 10,
    salePrice: 0,
  },
  artist: {
    price: 349,
    salePrice: 147,
    vipPrice: 47,
  },
  agency: {
    price: 1449,
    salePrice: 397,
    vipPrice: 197,
  },
  vip: {
    price: 50,
  },
}

export const STRIPE_PLAN_SUBSCRIPTIONS = {
  artist: STRIPE_ARTIST_ID,
  agency: STRIPE_AGENCY_ID,
}

export const STRIPE_PRODUCTS = {
  ANALYSIS: STRIPE_ANALYSIS_ID,
  PROFILE_FEATURED: STRIPE_PROFILE_FEATURED_ID,
  PLAYLIST_FEATURED: STRIPE_PLAYLIST_FEATURED_ID,
}

export const STRIPE_CONFIG = {
  PUBLIC_KEY: STRIPE_PUBLIC_KEY,
  SECRET_KEY: STRIPE_SECRET_KEY,
  CURRENCY: 'eur',
}
