export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export const splitArray = (array, parts) => {
  const result = []
  const arrayLength = array.length
  const chunkSize = Math.ceil(arrayLength / parts)

  for (let i = 0; i < arrayLength; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }

  return result
}

export const chunk = (arr, len) => {
  const chunks = []
  let i = 0
  const n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }
  return chunks
}

export const getRandomUniqueNumbers = (min, max, count) => {
  if (max - min + 1 < count) {
    throw new Error('Cannot generate unique numbers with given constraints.')
  }

  const numbers = []
  while (numbers.length < count) {
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
    if (!numbers.includes(randomNum)) {
      numbers.push(randomNum)
    }
  }

  return Array.from(numbers)
}

export const chunkArray = (array, chunkSize) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}
