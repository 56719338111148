export const ROUTES_DATA = {
  ACCELERATE: {
    icon: 'Rocket',
    path: '/accelerate',
    shortcut: 'A',
    seo: {
      changefreq: 'monthly',
      image: 'og_accelerate.jpg',
      key: 'accelerate',
      priority: 0.6
    },
    title: 'navigation.accelerate',
    id: 'accelerate'
  },
  ACCELERATES: {
    icon: 'Rocket',
    path: `/me/accelerates`,
    title: 'navigation.accelerates',
    id: 'accelerates'
  },
  ACCOUNT: {
    icon: 'UserCog',
    path: '/me/settings/account',
    title: 'navigation.account',
    id: 'account'
  },
  AFFILIATE: {
    icon: 'UserRound',
    path: '/affiliate',
    title: 'navigation.affiliate',
    id: 'affiliate'
  },
  ANALYSIS: {
    icon: 'FileChartLine',
    path: '/analysis',
    title: 'navigation.analysis',
    id: 'analysis'
  },
  ARTISTS: {
    icon: 'Headphones',
    path: '/me/artists',
    title: 'navigation.artists',
    id: 'artists',
    shortcut: 'A'
  },
  BILLING: {
    icon: 'CreditCard',
    path: '/me/billing',
    title: 'navigation.billing_info',
    id: 'billing'
  },
  BILLING_EDIT: {
    icon: 'CreditCard',
    path: '/me/billing/edit',
    title: 'navigation.billing_info',
    id: 'billing-edit'
  },
  BLOCKED: {
    icon: 'Ban',
    path: '/me/settings/blocked',
    title: 'navigation.blocked',
    id: 'blocked'
  },
  BLOCKED_USERS: {
    icon: 'Ban',
    path: '/me/settings/blocked-users',
    title: 'navigation.blocked_users',
    id: 'blocked-users'
  },
  BLOG: {
    icon: 'Newspaper',
    path: 'https://blog.matchfy.io/',
    title: 'navigation.blog',
    id: 'blog'
  },
  CHAT: {
    icon: 'MessageCircleMore',
    path: '/chat',
    shortcut: 'C',
    title: 'navigation.chat',
    id: 'chat'
  },
  CHAT_DETAIL: {
    icon: 'MessageCircleMore',
    path: '/chat/[id]',
    title: 'navigation.chat_detail',
    id: 'chat-detail'
  },
  CONTEST: {
    icon: 'Trophy',
    path: '/contest',
    title: 'navigation.contest',
    id: 'contest'
  },
  CONTRACT_OF_SERVICE: {
    icon: 'FileText',
    path: '/contract-of-service',
    title: 'navigation.contract_of_service',
    id: 'contract-of-service'
  },
  CURATOR_DETAIL: {
    icon: 'UserRound',
    path: '/curators/[id]',
    title: 'navigation.curator_detail',
    id: 'curator-detail'
  },
  CURATOR_PLAYLIST_DETAIL: {
    icon: 'Music',
    path: '/playlists/[id]',
    title: 'navigation.curator_playlist_detail',
    id: 'curator-playlist-detail'
  },
  CURATORS: {
    icon: 'Headphones',
    path: '/curators',
    seo: {
      changefreq: 'hourly',
      image: 'og_curators.jpg',
      key: 'curators',
      priority: 0.8
    },
    title: 'navigation.curators',
    id: 'curators'
  },
  DASHBOARD: {
    icon: 'Gauge',
    path: '/me/dashboard',
    shortcut: 'D',
    title: 'navigation.dashboard',
    id: 'dashboard'
  },
  DISTRIBUTION: {
    icon: 'SquareLibrary',
    path: '/distribution',
    seo: {
      changefreq: 'monthly',
      image: 'og_distribution.jpg',
      key: 'distribution',
      priority: 0.6
    },
    title: 'navigation.distribution',
    id: 'distribution'
  },
  DOWNLOAD_VIEW: {
    icon: 'Download',
    path: '/me/downloads/[id]',
    title: 'navigation.download_view',
    id: 'download-view'
  },
  DOWNLOADS: {
    icon: 'Download',
    path: '/me/downloads',
    title: 'navigation.downloads',
    id: 'downloads'
  },
  FACEBOOK: {
    icon: 'Facebook',
    path: 'https://www.facebook.com/matchfy.io',
    title: 'navigation.facebook',
    id: 'facebook'
  },
  FAQ: {
    icon: 'CircleHelp',
    path: '/faq',
    seo: {
      changefreq: 'yearly',
      key: 'faq',
      lastmod: '2023-09-23',
      priority: 0.3
    },
    title: 'navigation.faq',
    id: 'faq'
  },
  HOME: {
    icon: 'House',
    path: '/',
    shortcut: 'H',
    seo: {
      changefreq: 'daily',
      key: 'home',
      priority: 1.0
    },
    title: 'navigation.home',
    id: 'home'
  },
  INSTAGRAM: {
    icon: 'Instagram',
    path: 'https://www.instagram.com/matchfy.io',
    title: 'navigation.instagram',
    id: 'instagram'
  },
  INVOICE: {
    icon: 'ReceiptText',
    path: '/invoice/:invoiceId',
    title: 'navigation.invoice',
    id: 'invoice'
  },
  LOGIN: {
    icon: 'UserRound',
    path: '/login',
    title: 'navigation.login.title',
    id: 'login'
  },
  MAGAZINE: {
    icon: 'Newspaper',
    color: 'orange',
    path: '/match/magazine',
    subtitle: 'navigation.magazine.subtitle',
    title: 'navigation.magazine.title',
    id: 'magazine'
  },
  MAINTENANCE: {
    icon: 'Construction',
    path: '/maintenance',
    title: 'navigation.maintenance',
    id: 'maintenance'
  },
  MANAGEMENT: {
    icon: 'FileAudio',
    path: '/me/management',
    title: 'navigation.management',
    id: 'management'
  },
  MATCH: {
    icon: 'Music',
    path: '/match',
    shortcut: 'M',
    seo: {
      changefreq: 'monthly',
      image: 'og_match.jpg',
      key: 'match',
      priority: 0.8
    },
    title: 'navigation.match',
    id: 'match'
  },
  NOTIFICATIONS: {
    icon: 'Bell',
    path: '/notifications',
    title: 'navigation.notifications',
    id: 'notifications'
  },
  PARTNERS: {
    icon: 'PartyPopper',
    path: '/partners',
    title: 'navigation.partners',
    id: 'partners'
  },
  PARTNER_DETAIL: {
    icon: 'PartyPopper',
    path: '/partners/[id]',
    title: 'navigation.partners',
    id: 'partner-detail'
  },
  PAYMENT_REDIRECT: {
    icon: 'DollarSign',
    path: '/payment-redirect',
    title: 'navigation.payment_redirect',
    id: 'payment-redirect'
  },
  PAYMENTS: {
    icon: 'DollarSign',
    path: '/payments',
    title: 'navigation.payments',
    id: 'payments'
  },
  PLAYGROUND: {
    icon: 'Gamepad2',
    path: '/playground',
    title: 'navigation.playground',
    id: 'playground'
  },
  PLAYLIST_CHECK_DETAIL: {
    icon: 'Music',
    path: '/me/playlist-check/[id]',
    title: 'navigation.playlist_check_detail',
    id: 'playlist-check-detail'
  },
  PLAYLIST_CHECK: {
    icon: 'Check',
    path: '/me/playlist-check',
    title: 'navigation.playlist_check',
    id: 'playlist-check'
  },
  PLAYLIST_DETAIL: {
    icon: 'Music',
    path: '/me/playlists/[id]',
    title: 'navigation.playlists',
    id: 'playlists'
  },
  PLAYLIST_FEATURED: {
    icon: 'Music',
    path: '/playlist-featured',
    title: 'navigation.playlist_featured',
    id: 'playlist-featured'
  },
  PLAYLIST_FEATURED_DETAIL: {
    icon: 'Music',
    path: '/playlist-featured/[id]',
    title: 'navigation.playlist_featured_detail',
    id: 'playlist-featured-detail'
  },
  PLAYLISTS: {
    icon: 'ListMusic',
    path: '/me/playlists',
    shortcut: 'P',
    title: 'navigation.playlists',
    id: 'playlists'
  },
  POPULAR: {
    icon: 'Star',
    color: 'gold',
    path: '/popular',
    subtitle: 'submission.popular.subtitle',
    title: 'navigation.popular',
    id: 'popular'
  },
  PREFERENCES: {
    icon: 'Settings',
    path: '/me/settings',
    title: 'navigation.preferences',
    id: 'preferences'
  },
  PRICING: {
    icon: 'DollarSign',
    path: '/pricing',
    seo: {
      changefreq: 'monthly',
      image: 'og_pricing.jpg',
      key: 'pricing',
      priority: 1.0
    },
    title: 'navigation.pricing',
    id: 'pricing'
  },
  PRIVACY: {
    icon: 'ShieldCheck',
    path: '/privacy',
    title: 'navigation.privacy',
    id: 'privacy'
  },
  PROFILE: {
    icon: 'UserRoundPen',
    path: '/me/profile',
    shortcut: 'U',
    title: 'navigation.profile',
    id: 'profile'
  },
  PROFILE_EDIT: {
    icon: 'UserRoundPen',
    path: '/me/profile/edit',
    title: 'navigation.profile_edit',
    id: 'profile-edit'
  },
  PUBLIC_PROFILE: {
    icon: 'CircleUserRound',
    path: '/me/public-profile',
    title: 'navigation.public_profile',
    id: 'public-profile',
    shortcut: 'O'
  },
  PROMO: {
    icon: 'Percent',
    path: '/promo',
    title: 'navigation.promo',
    id: 'promo'
  },
  RADIO: {
    icon: 'Radio',
    color: 'blue',
    path: '/match/radio',
    subtitle: 'navigation.radio.subtitle',
    title: 'navigation.radio.title',
    id: 'radio'
  },
  RADIO_DETAIL: {
    icon: 'Radio',
    path: '/radio/[id]',
    title: 'navigation.radio_detail',
    id: 'radio-detail'
  },
  RANK: {
    icon: 'Trophy',
    color: 'gold',
    path: '/rank',
    subtitle: 'rank.subtitle',
    title: 'navigation.rank',
    id: 'rank',
    shortcut: 'R'
  },
  REPORT: {
    icon: 'Check',
    path: '/is-this-playlist-fake',
    seo: {
      changefreq: 'weekly',
      image: 'og_playlist_check.jpg',
      key: 'report',
      priority: 0.5
    },
    title: 'navigation.playlist_check',
    id: 'playlist-check'
  },
  REPORT_DETAIL: {
    icon: 'Check',
    path: '/report/[id]',
    title: 'navigation.report_detail',
    id: 'report-detail'
  },
  ROOT: {
    icon: 'House',
    path: '/*',
    title: 'navigation.root',
    id: 'home'
  },
  SETTINGS: {
    icon: 'Settings',
    path: '/me/settings',
    title: 'navigation.settings',
    id: 'settings',
  },
  SLA: {
    icon: 'FileText',
    path: '/sla',
    title: 'navigation.sla',
    id: 'sla'
  },
  SPOTIFY: {
    icon: 'Spotify',
    iconType: 'brand',
    color: 'green',
    path: '/match/spotify',
    seo: {
      changefreq: 'monthly',
      image: 'og_match.jpg',
      key: 'match',
      priority: 0.8
    },
    subtitle: 'navigation.spotify.subtitle',
    title: 'navigation.spotify.title',
    id: 'spotify'
  },
  SPOTIFY_SIGN_CALLBACK: {
    icon: 'Spotify',
    iconType: 'brand',
    path: '/sign',
    title: 'navigation.spotify_sign_callback',
    id: 'spotify-sign-callback'
  },
  SPOTIFY_SSO: {
    icon: 'Spotify',
    iconType: 'brand',
    path: '/spotify-sso',
    title: 'navigation.spotify_sso',
    id: 'spotify-sso'
  },
  SPOTIFY_SSO_CALLBACK: {
    icon: 'Spotify',
    iconType: 'brand',
    path: '/sso-callback',
    title: 'navigation.spotify_sso_callback',
    id: 'spotify-sso-callback'
  },
  STARS: {
    icon: 'Star',
    path: '/stars',
    title: 'navigation.stars',
    id: 'stars'
  },
  SUBMISSIONS: {
    icon: 'ArrowRightToLine',
    path: '/me/submissions',
    shortcut: 'O',
    title: 'navigation.submissions',
    id: 'submissions'
  },
  SUBMISSIONS_DETAIL: {
    icon: 'ArrowRightToLine',
    path: '/me/submissions/[id]',
    title: 'navigation.submissions',
    id: 'submissions-detail'
  },
  SUPPORT: {
    icon: 'LifeBuoy',
    path: '/support',
    seo: {
      changefreq: 'monthly',
      image: 'og_support.jpg',
      key: 'support',
      priority: 0.3
    },
    title: 'navigation.support',
    id: 'support'
  },
  TEAM: {
    icon: 'UsersRound',
    path: '/team',
    title: 'navigation.team',
    id: 'team'
  },
  TERMS: {
    icon: 'FileText',
    path: '/terms',
    seo: {
      changefreq: 'yearly',
      key: 'terms',
      lastmod: '2024-02-21',
      priority: 0.1
    },
    title: 'navigation.terms',
    id: 'terms'
  },
  TIKTOK: {
    icon: 'TikTok',
    iconType: 'brand',
    color: 'tiktok',
    path: '/match/tiktok',
    subtitle: 'navigation.tiktok.subtitle',
    title: 'navigation.tiktok.title',
    id: 'tiktok'
  },
  TIKTOK_DETAIL: {
    icon: 'TikTok',
    iconType: 'brand',
    path: '/tiktok/[id]',
    title: 'navigation.tiktok_detail',
    id: 'tiktok-detail'
  },
  TRACK_DETAIL: {
    icon: 'CirclePlay',
    path: '/plays/[id]',
    title: 'navigation.track_detail',
    id: 'plays-detail'
  },
  TRACKS: {
    icon: 'CirclePlay',
    path: '/plays',
    seo: {
      changefreq: 'monthly',
      image: 'og_vip_plays.jpg',
      key: 'vip_tracks',
      priority: 0.4
    },
    title: 'navigation.vip_tracks',
    id: 'plays'
  },
  TRANSACTIONS: {
    icon: 'DollarSign',
    path: '/me/billing/transactions',
    title: 'navigation.payments_history',
    id: 'payments-history'
  },
  TUTORIALS: {
    icon: 'Video',
    path: '/tutorials',
    title: 'navigation.tutorials',
    id: 'tutorials'
  },
  USER_VERIFY: {
    icon: 'UserRoundCheck',
    path: '/user/verify',
    title: 'navigation.user_verify',
    id: 'user-verify'
  },
  VIP: {
    icon: 'Gem',
    path: '/vip',
    seo: {
      changefreq: 'monthly',
      image: 'og_vip.jpg',
      key: 'vip',
      priority: 0.8
    },
    title: 'navigation.vip',
    id: 'vip'
  },
  VIP_CAMPAIGN_DETAIL: {
    icon: 'Info',
    path: '/me/vip-campaigns/[id]',
    title: 'navigation.vip_campaigns',
    id: 'vip-campaigns'
  },
  VIP_CAMPAIGN_REPORT: {
    icon: 'FileAudio',
    path: '/me/vip-campaigns/[id]/report',
    title: 'navigation.vip_campaign_report',
    id: 'vip-campaign-report'
  },
  VIP_CAMPAIGNS: {
    icon: 'Gem',
    path: '/me/vip-campaigns',
    title: 'navigation.vip_campaigns',
    id: 'vip-campaigns',
    shortcut: 'V'
  },
  VIP_MANAGER: {
    icon: 'UserRoundCheck',
    path: '/me/vip-campaigns/manager',
    title: 'navigation.vip_manager',
    id: 'vip-manager'
  },
  WANTED: {
    icon: 'Heart',
    path: '/me/wanted',
    title: 'navigation.wanted',
    id: 'wanted'
  }
}