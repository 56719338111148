export const applyTheme = (primaryColor, secondaryColor) => {
  document.body.style.setProperty(
    '--theme-primary-color',
    `var(--${primaryColor}-color)`
  )
  document.body.style.setProperty(
    '--theme-primary-color-rgb',
    `var(--${primaryColor}-color-rgb)`
  )
  document.body.style.setProperty(
    '--theme-secondary-color',
    `var(--${secondaryColor}-color)`
  )
  document.body.style.setProperty(
    '--theme-secondary-color-rgb',
    `var(--${secondaryColor}-color-rgb)`
  )
}

export const resetTheme = () => {
  document.body.style.setProperty(
    '--theme-primary-color',
    'var(--primary-color)'
  )
  document.body.style.setProperty(
    '--theme-primary-color-rgb',
    'var(--primary-color-rgb)'
  )
  document.body.style.setProperty(
    '--theme-secondary-color',
    'var(--secondary-color)'
  )
  document.body.style.setProperty(
    '--theme-secondary-color-rgb',
    'var(--secondary-color-rgb)'
  )
}