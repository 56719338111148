export const SUBMISSION_TYPOLOGY = {
  MATCH: 'match',
  VIP: 'vip',
}

export const SUBMISSION_FEEDBACK = [
  {
    id: 'not_matching_theme',
    icon: 'Ban',
    iconColor: 'red',
    label: 'playlist.queue.feedback.reasons.not_matching_theme.label',
    description: 'playlist.queue.feedback.reasons.not_matching_theme.description',
  },
  {
    id: 'low_production_quality',
    icon: 'TriangleAlert',
    iconColor: 'orange',
    label: 'playlist.queue.feedback.reasons.low_production_quality.label',
    description: 'playlist.queue.feedback.reasons.low_production_quality.description',
  },
  {
    id: 'popularity_appeal',
    icon: 'Star',
    iconColor: 'gold',
    label: 'playlist.queue.feedback.reasons.popularity_appeal.label',
    description: 'playlist.queue.feedback.reasons.popularity_appeal.description',
  },
  {
    id: 'excessive_submissions',
    icon: 'Inbox',
    iconColor: 'blue',
    label: 'playlist.queue.feedback.reasons.excessive_submissions.label',
    description: 'playlist.queue.feedback.reasons.excessive_submissions.description',
  },
  {
    id: 'lack_of_originality',
    icon: 'Lightbulb',
    iconColor: 'green',
    label: 'playlist.queue.feedback.reasons.lack_of_originality.label',
    description: 'playlist.queue.feedback.reasons.lack_of_originality.description',
  },
]