import { cn } from '@/utils';
const FankeeLogo = ({
  className
}) => <svg className={cn({
  [className]: className
})} viewBox="0 0 1131 569" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="FankeeLogo" data-sentry-source-file="FankeeLogo.jsx">
    <g clipPath="url(#clip0_1532_61208)" data-sentry-element="g" data-sentry-source-file="FankeeLogo.jsx">
      <path d="M0 0H315.89V72.52H148.07V109.92H281.72V183.2H148.07V272.23H0V0Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FankeeLogo.jsx" />
      <path d="M382.22 0H594.28L709.13 272.23H549.09L535.8 232.74H437.47L424.19 272.23H267.95L382.22 0ZM512.83 159.46L486.45 78.78L460.25 159.46H512.83Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FankeeLogo.jsx" />
      <path d="M718.47 0H888.76L998.11 149.41V0H1130.24V272.23H963.93L850.8 116.94V272.23H718.47V0Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FankeeLogo.jsx" />
      <path d="M148.07 436.32V568.26H0V296.03H148.07V412.02L231.8 296.03H407.2L297.85 416.78L409.86 568.26H235.02L148.07 436.32Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FankeeLogo.jsx" />
      <path d="M414.74 296.03H764.2V363.43H566.5V394.75H729.18V464.61H566.5V498.98H764.2V568.27H414.74V296.04V296.03Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FankeeLogo.jsx" />
      <path d="M780.78 296.03H1130.24V363.43H932.55V394.75H1095.21V464.61H932.55V498.98H1130.24V568.27H780.78V296.04V296.03Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FankeeLogo.jsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="FankeeLogo.jsx">
      <clipPath id="clip0_1532_61208" data-sentry-element="clipPath" data-sentry-source-file="FankeeLogo.jsx">
        <rect width="1130.24" height="568.26" fill="white" data-sentry-element="rect" data-sentry-source-file="FankeeLogo.jsx" />
      </clipPath>
    </defs>
  </svg>;
export default FankeeLogo;