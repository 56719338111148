import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration)
dayjs.extend(relativeTime)

export const humanReadableDuration = (dateString, locale, showFuture = false) => {
  if (!dateString) return

  const inputDate = dayjs.isDayjs(dateString) ? dateString : dayjs().add(dateString, 'hours')
  const now = dayjs()
  const duration = dayjs.duration(showFuture ? now.diff(inputDate) : inputDate.diff(now))

  const months = Math.floor(duration.asMonths())
  const years = Math.floor(duration.asYears())

  if (years >= 1) {
    return dayjs.duration({ years }).locale(locale).humanize(true)
  } else if (months >= 1) {
    return dayjs.duration({ months }).locale(locale).humanize(true)
  }

  return duration.locale(locale).humanize(true)
}