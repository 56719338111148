export const MATCH_POSITION_OPTIONS = (t) => [
  {
    label: t('settings.match.song_position.options.first'),
    value: 'first',
  },
  {
    label: t('settings.match.song_position.options.last'),
    value: 'last',
  },
]

export const DIGEST_FREQUENCY_OPTIONS = (t) => [
  {
    label: t('settings.notifications.digest_frequency.options.always'),
    value: 'always',
  },
  {
    label: t('settings.notifications.digest_frequency.options.daily'),
    value: 'daily',
  },
  {
    label: t('settings.notifications.digest_frequency.options.weekly'),
    value: 'weekly',
  },
  {
    label: t('settings.notifications.digest_frequency.options.never'),
    value: 'never',
  },
]