export const PLAYLIST_COVER_DATA = [
  { title: 'Electric Grooves' },
  { title: 'Flamenco Fiesta' },
  { title: 'Live Concert' },
  { title: 'Milano by Night' },
  { title: 'Flow Mediterraneo' },
  { title: 'Rock Classics' },
  { title: 'Afrobeat Wave' },
  { title: 'Roma Trap Wave' },
  { title: 'Latin Trap' },
  { title: 'Sax Improv' },
  { title: 'Techno Hits' },
  { title: 'Afternoon Tea' },
  { title: 'Milano Fashion Week' },
  { title: 'Street Advisor' },
  { title: 'Latin Queens' },
  { title: 'Piano Italiano' },
  { title: 'NYC Sunset' },
  { title: 'Reggae Vibes' },
  { title: 'London Nights' },
  { title: 'Italian Summer' },
  { title: 'Metropolis' },
  { title: 'Boat Party' },
  { title: 'Legendary Live Shows' },
  { title: 'Rap Battle Royale' },
  { title: 'Experimental Beats' },
  { title: 'Urban Life' },
  { title: 'Unplugged and Raw' },
  { title: 'Bassline Bangers' },
  { title: 'Roadtrip' },
  { title: 'Guitar Solos' },
  { title: 'Party Vibes' },
  { title: 'Studio Bounces' },
  { title: 'New Releases' },
  { title: 'Back from work' },
  { title: 'Movie Scores' },
  { title: 'Voice Memos' },
  { title: 'Studio Sessions' },
  { title: 'Money Gang' },
  { title: 'Beast Mode' },
  { title: 'Hip Hop Hits' },
  { title: 'Acoustic Night Lives' },
  { title: 'DJ Mixes' },
  { title: 'Spanish Feelings' },
  { title: 'Sleepover Time!' },
  { title: 'Mixing Tapes' },
  { title: 'Party all night' },
  { title: 'Dancefloor Hits' },
  { title: 'Soul Melodies' },
  { title: 'Drum and Bass' },
  { title: 'Girls Night' },
  { title: 'Dreamy Moods' },
  { title: 'Selfmade Attitude' },
  { title: 'New Music Friday' },
  { title: 'Radio Head' },
  { title: 'Street Performers' },
  { title: 'Reggaeton Essentials' },
  { title: 'Latino Hits' },
  { title: 'Indie Spirit' },
  { title: 'Energy Boost' },
  { title: 'Jazz Classics' },
]
