// Pagination
export const AFFILIATE_MAX_USAGE = 10
export const CURATOR_PLAYLISTS_PER_PAGE = 10
export const CURATOR_SUBMISSIONS_PER_PAGE = 10
export const GENRES_QUERY_LIMIT = 10
export const MAXIMUM_GENRES_PER_MAGAZINE = 10
export const MAXIMUM_GENRES_PER_PLAYLIST = 10
export const MAX_ITEMS_REPEATER_FIELD = 6
export const PER_PAGE = 50
export const TRACKS_PER_PAGE = 20
export const PLAYLISTS_PER_PAGE = 20
export const SEARCH_PER_PAGE = 20
export const SUBMISSIONS_PER_PAGE = 20
export const TABLE_PER_PAGE_OPTIONS = [20, 50, 100]