export const LANGUAGES = [
  {
    label: 'languages.italian',
    value: 'it',
    bpc47: 'it-IT'
  },
  {
    label: 'languages.english',
    value: 'en',
    bpc47: 'en-US'
  },
  {
    label: 'languages.spanish',
    value: 'es',
    bpc47: 'es-ES'
  },
  {
    label: 'languages.french',
    value: 'fr',
    bpc47: 'fr-FR'
  },
]