// Company Information
export const COMPANY_NAME = 'Planet X'
export const VAT_NUMBER = 'IT04551600986'

// Environment & Mode
export const DEVELOPMENT = process.env.NODE_ENV === 'development'
export const PRODUCTION = process.env.NODE_ENV === 'production'
export const DEBUG_REQUESTS = false

// API URLs & Keys
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME
export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL
export const BLOG_API_URL = process.env.NEXT_PUBLIC_BLOG_API_URL
export const CB_BLOG_API_URL = 'https://blog.clockbeats.com/ghost/api/content'
export const MATCHFY_BLOG_API_URL = 'https://blog.matchfy.io/ghost/api/content'
export const GHOST_KEY = process.env.NEXT_PUBLIC_GHOST_KEY
export const SANITY_PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID
export const CALENDLY_WIDGET_URL = 'https://calendly.com/alessia-matchfy/30min'

// Google & Maps Related
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
export const GOOGLE_MAP_SCRIPT_BASE_URL = 'https://maps.googleapis.com/maps/api/js'

// Spotify Related
export const SPOTIFY_API_URL = process.env.NEXT_PUBLIC_MATCHFY_SPOTIFY_API_URL
export const SPOTIFY_REDIRECT_URL = process.env.NEXT_PUBLIC_SPOTIFY_REDIRECT_URL
export const SPOTIFY_SUPPORT_URL = 'https://support.spotify.com/us/contact-spotify-support/'
export const SPOTIFY_ID_LENGTH = 22

// User Related Settings
export const USER_PROFILE_FETCH_TIMEOUT = 1 * 60 * 1000 // 1 minute
export const TRACK_USER_ACTIVITY_THROTTLE = 60000 // 1 minute
export const TRACK_USER_DASHBOARD_ANALYTICS_THROTTLE = 4 * 60 * 60 * 1000 // 4 hours
export const DASHBOARD_ANALYTICS_COOLDOWN = 10 * 60 * 1000 // 10 minutes
export const USER_TYPES = {
  STAR: 'star',
}

// UI Related
export const BREAKPOINTS = {
  XS: 575,
  SM: 767,
  MD: 959,
  LG: 1279,
  XL: 1439,
}

export const TIKTOK_PROFILE_TYPES = {
  USER: 'user',
  AGENCY: 'agency',
}

// External URLs & Tutorials
export const WHISTLEBLOWING_URL = "https://safespeak.io/report-form/9b01d7af-c6ff-4451-9b9b-7c1ca6e33d05"
export const TUTORIAL_URL = 'https://www.youtube.com/watch?v=JhvymWL_4wg'
export const TIKTOK_CODE_TUTORIAL_URL = 'https://www.youtube.com/watch?v=BwlWcr76K1Y'
export const TIKTOK_SOUND_TUTORIAL_URL = 'https://www.tiktok.com/music/OCCHI-LUCIDI-7306990675446450177'
export const WANTED_URL = 'https://wanted.live'
export const DISTRIBUTION_TUTORIAL_URL_IT = 'https://www.youtube.com/embed/wFCoHQw_nlk'
export const DISTRIBUTION_TUTORIAL_URL_EN = 'https://www.youtube.com/embed/6XmwkEw0jUw'
