import { cn } from '@/utils';
const SpotifyLogoIcon = ({
  className
}) => <svg className={cn({
  [className]: className
})} viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SpotifyLogoIcon" data-sentry-source-file="SpotifyLogoIcon.jsx">
    <g clipPath="url(#clip0_277_666)" data-sentry-element="g" data-sentry-source-file="SpotifyLogoIcon.jsx">
      <path d="M119.365 66.489C95.1891 52.1315 55.312 50.8116 32.2332 57.8161C28.5262 58.9405 24.6075 56.8488 23.4851 53.1424C22.362 49.434 24.4517 45.5173 28.1601 44.3909C54.6537 36.3494 98.6933 37.9023 126.525 54.4233C129.859 56.4028 130.951 60.7077 128.976 64.0353C126.999 67.3689 122.69 68.4684 119.364 66.489M118.573 87.7535C116.877 90.5061 113.278 91.3692 110.529 89.6792C90.3751 77.2905 59.6404 73.7009 35.7952 80.9391C32.7034 81.8728 29.437 80.1304 28.498 77.0433C28.0503 75.5593 28.2094 73.9583 28.9403 72.5914C29.6712 71.2245 30.9144 70.2032 32.3971 69.7514C59.6363 61.4848 93.4998 65.4881 116.649 79.7146C119.398 81.4073 120.263 85.0076 118.573 87.7535ZM109.395 108.176C109.076 108.7 108.656 109.157 108.16 109.519C107.664 109.881 107.101 110.142 106.504 110.287C105.907 110.431 105.288 110.457 104.681 110.361C104.074 110.266 103.492 110.052 102.968 109.731C85.3562 98.9674 63.1883 96.5365 37.0815 102.5C36.4833 102.637 35.8641 102.654 35.2591 102.552C34.6541 102.449 34.0752 102.228 33.5556 101.902C33.0359 101.576 32.5856 101.15 32.2305 100.65C31.8753 100.149 31.6222 99.5838 31.4857 98.9855C31.3481 98.3871 31.33 97.7675 31.4323 97.162C31.5346 96.5566 31.7553 95.9773 32.0818 95.4573C32.4083 94.9374 32.8342 94.4869 33.335 94.1317C33.8359 93.7766 34.4019 93.5237 35.0006 93.3876C63.5698 86.8574 88.0759 89.6678 107.844 101.748C108.369 102.068 108.824 102.488 109.186 102.984C109.548 103.481 109.808 104.043 109.952 104.64C110.096 105.237 110.121 105.857 110.026 106.463C109.93 107.07 109.716 107.652 109.395 108.176ZM74.9987 0C33.5793 0 0 33.578 0 74.9987C0 116.423 33.5793 150 74.9993 150C116.421 150 150 116.423 150 74.9987C150 33.5786 116.421 0 74.9987 0Z" fill="#1ED660" data-sentry-element="path" data-sentry-source-file="SpotifyLogoIcon.jsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="SpotifyLogoIcon.jsx">
      <clipPath id="clip0_277_666" data-sentry-element="clipPath" data-sentry-source-file="SpotifyLogoIcon.jsx">
        <rect width="150" height="150" fill="white" data-sentry-element="rect" data-sentry-source-file="SpotifyLogoIcon.jsx" />
      </clipPath>
    </defs>
  </svg>;
export default SpotifyLogoIcon;