import { ROUTES } from './routes'

export const TOUR_OPTIONS = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    highlightClass: 'highlight',
    scrollTo: false,
    allowHTML: true,
  },
  useModalOverlay: true,
  scrollTo: { behavior: 'smooth', block: 'center' }
}

export const ANALYSIS_TOUR_STEPS = {
  tourId: 'analysis',
  route: ROUTES.ANALYSIS,
  delay: 1000,
  steps: [
    {
      title: 'tour.analysis.0.title',
      text: 'tour.analysis.0.text',
    },
  ],
}

export const ACCELERATE_TOUR_STEPS = {
  tourId: 'accelerate',
  route: ROUTES.ACCELERATE,
  delay: 1000,
  steps: [
    {
      tourId: 'accelerate',
      title: 'tour.accelerate.0.title',
      text: 'tour.accelerate.0.text',
    },
  ],
}

export const CURATOR_TOUR_STEPS = {
  tourId: 'curator',
  route: ROUTES.CURATORS,
  delay: 1000,
  steps: [
    {
      tourId: 'curator',
      title: 'tour.curators.0.title',
      text: 'tour.curators.0.text',
    },
  ],
}

export const PROFILE_PLAYLIST_TOUR_STEPS = {
  tourId: 'profile-playlist',
  route: ROUTES.PLAYLISTS,
  delay: 5000,
  steps: [
    {
      tourId: 'profile-playlist',
      attachTo: { element: '.playlist-verify', on: 'bottom' },
      title: 'tour.profile.playlist.0.title',
      text: 'tour.profile.playlist.0.text',
    },
    {
      attachTo: { element: '.playlist-requests', on: 'bottom' },
      title: 'tour.profile.playlist.1.title',
      text: 'tour.profile.playlist.1.text',
    },
  ],
}

export const PROFILE_OVERVIEW_TOUR_STEPS = {
  tourId: 'profile-overview',
  route: ROUTES.PROFILE,
  delay: 1000,
  steps: [
    {
      title: 'tour.profile.overview.0.title',
      text: 'tour.profile.overview.0.text',
    },
    {
      attachTo: {
        element: '.profile-sidebar-header .credit-balance',
        on: 'right',
      },
      attachToMobile: { element: '.credit-balance', on: 'bottom' },
      title: 'tour.profile.credits.0.title',
      text: 'tour.profile.credits.0.text',
    },
    {
      attachTo: { element: '#nav-profile', on: 'right' },
      attachToMobile: { element: '#nav-profile', on: 'bottom' },
      title: 'tour.profile.overview.1.title',
      text: 'tour.profile.overview.1.text',
    },
    {
      attachTo: { element: '#nav-artists', on: 'right' },
      attachToMobile: { element: '#nav-artists', on: 'bottom' },
      title: 'tour.profile.overview.2.title',
      text: 'tour.profile.overview.2.text',
    },
    {
      attachTo: { element: '#nav-submissions', on: 'right' },
      attachToMobile: { element: '#nav-submissions', on: 'bottom' },
      title: 'tour.profile.overview.3.title',
      text: 'tour.profile.overview.3.text',
    },
    {
      attachTo: { element: '#nav-vip-campaigns', on: 'right' },
      attachToMobile: { element: '#nav-vip-campaigns', on: 'bottom' },
      title: 'tour.profile.overview.4.title',
      text: 'tour.profile.overview.4.text',
    },
    {
      attachTo: { element: '#nav-playlists', on: 'right' },
      attachToMobile: { element: '#nav-playlists', on: 'bottom' },
      title: 'tour.profile.overview.5.title',
      text: 'tour.profile.overview.5.text',
    },
  ],
}

export const MATCH_TOUR_STEPS = {
  tourId: 'match',
  route: ROUTES.MATCH,
  delay: 1000,
  steps: [
    {
      attachTo: { element: '#match-step-1', on: 'bottom' },
      title: 'tour.match.0.title',
      text: 'tour.match.0.text',
    },
    {
      attachTo: { element: '#match-step-2', on: 'bottom' },
      title: 'tour.match.1.title',
      text: 'tour.match.1.text',
    },
    {
      attachTo: { element: '.button-gradient', on: 'bottom' },
      title: 'tour.match.2.title',
      text: 'tour.match.2.text',
    },
  ],
}

export const VERIFY_EMAIL_TOUR_STEPS = {
  tourId: 'verify-email',
  route: ROUTES.PROFILE,
  delay: 1000,
  steps: [
    {
      attachTo: { element: '.info-email', on: 'bottom' },
      title: 'tour.profile.verify_email.0.title',
      text: 'tour.profile.verify_email.0.text',
    },
  ],
}

export const TOURS = [
  ANALYSIS_TOUR_STEPS,
  ACCELERATE_TOUR_STEPS,
  CURATOR_TOUR_STEPS,
  PROFILE_PLAYLIST_TOUR_STEPS,
  PROFILE_OVERVIEW_TOUR_STEPS,
  MATCH_TOUR_STEPS,
  VERIFY_EMAIL_TOUR_STEPS,
]
