import dayjs from 'dayjs'

export const DAYS_SINCE_LAUNCH = dayjs().diff(dayjs('2019-04-25'), 'day')
export const COUNTER_DURATION = 3

export const COUNTERS = {
  AGENCY_PLAN_COUNT: DAYS_SINCE_LAUNCH * 2,
  ARTIST_PLAN_COUNT: DAYS_SINCE_LAUNCH * 7,
  ACCELERATE: DAYS_SINCE_LAUNCH / 7,
  ARTISTS: 2000 + DAYS_SINCE_LAUNCH * 50,
  ANALYSIS: DAYS_SINCE_LAUNCH / 2,
  CURATORS: DAYS_SINCE_LAUNCH / 10,
  FAKE_PLAYLISTS: DAYS_SINCE_LAUNCH,
  PLAYS: DAYS_SINCE_LAUNCH * 500,
  PLAYLISTS: 8000000 + DAYS_SINCE_LAUNCH * 150,
  SUBMISSIONS: 13000000 + DAYS_SINCE_LAUNCH * 300
}

export const DAILY_UPLOADED_SONGS = 100000