export const IUBENDA_SITE_ID = process.env.NEXT_PUBLIC_IUBENDA_SITE_ID
export const IUBENDA_COOKIE_POLICY_ID = process.env.NEXT_PUBLIC_IUBENDA_COOKIE_POLICY_ID
export const IUBENDA_CONFIG = {
  lang: "en",
  invalidateConsentWithoutLog: true,
  consentOnContinuedBrowsing: true,
  enableTcf: true,
  googleAdditionalConsentMode: true,
  perPurposeConsent: true,
  siteId: IUBENDA_SITE_ID,
  cookiePolicyId: IUBENDA_COOKIE_POLICY_ID,
  applyStyles: true,
  banner: {
    acceptButtonDisplay: true,
    customizeButtonDisplay: true,
    position: 'float-center',
    acceptButtonColor: '#5e4af9',
    backgroundOverlay: true,
    acceptButtonCaptionColor: 'white',
    customizeButtonColor: '#DADADA',
    customizeButtonCaptionColor: '#4D4D4D',
    textColor: '#fafafa',
    backgroundColor: '#1c1c1c',
    closeButtonDisplay: false,
    listPurposes: true,
  },
  callback: {},
}

export const IUBENDA_TERMS = 'https://www.iubenda.com/api/terms-and-conditions/42480010/no-markup'
export const IUBENDA_PRIVACY = 'https://www.iubenda.com/api/privacy-policy/42480010/no-markup'