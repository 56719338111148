const defaultBillingInfo = {
  firstName: '',
  lastName: '',
  companyName: '',
  country: '',
  city: '',
  address: '',
  provinceState: '',
  zipCode: '',
  vatNumber: '',
  pec: '',
  email: '',
  isVatCompany: false,
  codiceFiscale: '',
  codiceUnivoco: '',
}

export const getBillingInfo = (billingInfo) => {
  return !billingInfo
    ? defaultBillingInfo
    : { ...defaultBillingInfo, ...billingInfo }
}