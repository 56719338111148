export const QUERY_STALE_TIME = 1000 * 60 * 60 * 24 // 24 hours

export const QUERIES = {
  ACCELERATE: {
    HAS_ACCELERATE: 'accelerate.has_accelerate'
  },
  ANALYSIS: {
    GET: 'analysis.get'
  },
  AUTOCOMPLETE: 'autocomplete',
  CHAT: {
    COUNT: 'chat.count',
    LIST: 'chat.list'
  },
  CURATOR: {
    STATS: 'curator.stats'
  },
  DASHBOARD: {
    ARTIST: 'dashboard.artist',
    CURATOR: 'dashboard.curator'
  },
  MAGAZINE: {
    LIST: 'magazine.list'
  },
  PLAYLIST: {
    ANALYSIS: 'playlist.analysis',
    CHARTS: 'playlist.charts',
    GENRES: 'playlists.genres',
    GENRES_CHECK_UPDATE: 'playlists.genres_check_update',
    LIST: 'playlist.list',
    PUSHED: 'playlists.pushed',
    QUEUE: 'playlist.queue',
    SPOTIFY_DATA: 'playlist.spotify_data'
  },
  PLAYLIST_CHECK: {
    MONITORED: 'playlist_check.monitored'
  },
  PLAYLIST_FEATURED: {
    LIST: 'playlist_featured.list'
  },
  PROMO: {
    LIST: 'promo.list'
  },
  RADIO: {
    LIST: 'radio.list'
  },
  SPOTIFY: {
    LIST: 'spotify.list'
  },
  SUBMISSION: {
    POPULAR: 'submissions.popular'
  },
  TIKTOK: {
    LIST: 'tiktok.list'
  },
  TOKEN: {
    GET: 'token.get'
  },
  TRACK_PLAYS: {
    LIST: 'track_plays.list'
  },
  TRANSACTION: {
    LIST: 'transaction.list'
  },
  TRACK_PUSHED: {
    DETAIL: 'track_pushed.detail',
    LIST: 'track_pushed.list'
  },
  USER: {
    BLOCKED: 'user.blocked',
    STARS: 'user.stars'
  },
  VIP_CAMPAIGN: {
    DETAIL: 'vip_campaign.detail',
    LIST: 'vip_campaign.list'
  },
  WANTED: {
    COUNTERS: 'wanted.counters',
    LIST: 'wanted.list'
  }
}