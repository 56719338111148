import { getTranslations } from 'next-intl/server'
import { APP_NAME, APP_URL, ROUTES_DATA as ROUTES } from '@/data'

export const getPageMetadata = async (routeKey) => {
  const ogImagePath = `${APP_URL}/images/og`
  const defaultOgImage = `${ogImagePath}/og_default.jpg`

  const t = await getTranslations()
  const route = ROUTES?.[routeKey]
  if (!route) return null

  const pageTitle = route.title ? t(route.title) : APP_NAME
  const metadata = {
    title: `${APP_NAME} | ${pageTitle}`,
    description: t('og.description.default'),
    image: defaultOgImage
  }

  if (route && route?.seo) {
    const pageDescription = t(`og.description.${route.seo.key}`)
    metadata.description = pageDescription
    metadata.openGraph = {
      description: pageDescription,
      images: [
        { url: `${ogImagePath}/${route.seo.image}` }
      ]
    }
  }

  return metadata
}