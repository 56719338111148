import { cn } from '@/utils';
const ShazamLogoIcon = ({
  className
}) => <svg className={cn({
  [className]: className
})} viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ShazamLogoIcon" data-sentry-source-file="ShazamLogoIcon.jsx">
    <path d="M0 75.0011C0 116.519 33.7054 150.001 75 150.001C116.518 150.001 150 116.296 150 75.0011C150 33.4833 116.295 0.00110087 75 0.00110087C33.7054 -0.222113 0 33.4833 0 75.0011Z" fill="url(#paint0_linear_288_250)" data-sentry-element="path" data-sentry-source-file="ShazamLogoIcon.jsx" />
    <path d="M115.179 102.903C108.036 110.715 95.3125 122.992 94.6428 123.438C92.8571 125.001 90.8482 125.894 88.616 125.894C86.3839 125.894 84.1518 125.001 82.366 123.215C79.0178 119.644 79.241 114.287 82.5892 110.938C82.8124 110.715 95.5357 98.6615 102.232 91.2955C105.357 87.724 106.92 83.2598 106.696 78.5723C106.473 73.6615 104.018 68.974 100 65.6258C94.6428 61.1615 84.375 60.0455 77.9018 65.8491C74.1071 69.4205 69.4196 74.3312 69.1964 74.5544C65.8482 78.1258 60.491 78.3491 56.9196 75.0009C53.3482 71.6526 53.125 66.2955 56.4732 62.7241C56.6964 62.5009 61.8303 56.9205 66.2946 52.9026C78.5714 41.7419 98.8839 41.5187 111.384 52.4562C118.973 58.9294 123.437 67.858 124.107 77.4562C124.777 86.8312 121.652 95.983 115.179 102.903ZM62.7232 103.572C54.6875 103.572 46.6517 100.894 40.4017 95.5366C32.8125 89.0633 28.3482 80.1348 27.6785 70.5366C27.0089 61.1616 30.3571 52.233 36.6071 45.0901C43.75 37.2776 56.4732 25.0008 57.1428 24.5544C60.7142 21.2062 66.0714 21.4294 69.4196 24.7776C72.7678 28.3491 72.5446 33.7062 69.1964 37.0544C68.9732 37.2776 56.2499 49.3312 49.5535 56.6973C46.4285 60.2687 44.866 64.733 45.0892 69.4205C45.3124 74.3312 47.7678 79.0187 51.7857 82.3669C57.1428 86.8312 67.4107 87.9473 73.8839 82.1437C77.6785 78.5723 82.366 73.6615 82.5892 73.4383C85.9374 69.8669 91.2946 69.6437 94.866 72.9919C98.4374 76.3401 98.6607 81.6973 95.3124 85.2687C95.0892 85.4919 89.9553 91.0723 85.491 95.0901C79.241 100.67 70.9821 103.572 62.7232 103.572Z" fill="white" data-sentry-element="path" data-sentry-source-file="ShazamLogoIcon.jsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="ShazamLogoIcon.jsx">
      <linearGradient id="paint0_linear_288_250" x1="75.1044" y1="149.954" x2="75.1044" y2="-37.7797" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ShazamLogoIcon.jsx">
        <stop stopColor="#2255FF" data-sentry-element="stop" data-sentry-source-file="ShazamLogoIcon.jsx" />
        <stop offset="1" stopColor="#00AAFF" data-sentry-element="stop" data-sentry-source-file="ShazamLogoIcon.jsx" />
      </linearGradient>
    </defs>
  </svg>;
export default ShazamLogoIcon;